import { Box, Grid, Hidden, Typography } from "@mui/material";
import { setHours } from "date-fns";
import { useState } from "react";

const commonStyles = {
  letterSpacing: "-.025em",
  lineHeight: 1,
  marginRight: "16px",
  fontWeight: 700,
};
export default function DaysUntilBanner() {
  const [dateString, setDateString] = useState("");

  setInterval(function() {
    const dateFuture = setHours(new Date("5/12/2022"), 12) as any;

    const dateNow = new Date() as any;
    let seconds = Math.floor((dateFuture - dateNow) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    setDateString(`${days}  :  ${hours}  :  ${minutes}  :  ${seconds}`);
  }, 1000);

  return (
    <Box
      sx={{
        height: 60,
        width: "100%",
        backgroundColor: "teal.light",
      }}
    >
      <Grid
        sx={{ height: "100%" }}
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Hidden only={["xs", "sm"]}>
          <Box sx={{ display: "flex", marginRight: "50px" }}>
            <Typography sx={commonStyles}>May 12-15, 2022</Typography>
          </Box>
        </Hidden>
        <Box>
          <Typography sx={[commonStyles, { lineHeight: "29px", fontSize: 22 }]}>
            {dateString}
          </Typography>
          <Typography sx={[commonStyles, { fontSize: 11 }]}>
            Countdown to Amplitude Festival 2022
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
}
