import { BrowserRouter } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import "./App.css";

import Router from "./Router";
import Navigation from "./Navigation/Navigation";
import { Box } from "@mui/system";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Box sx={{ backgroundColor: "darkPurple.dark" }}>
          <Navigation />

          <main>
            <Router />
          </main>
        </Box>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
