import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";

import { navItems } from "./constants";

export default function DesktopNav() {
  return (
    <Box
      sx={{
        minHeight: 64,
        paddingLeft: "50px",
        paddingRight: "50px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        {Object.entries(navItems).map(([key, { to, label }]) => (
          <Grid key={key} item>
            <Box
              sx={[
                {
                  textTransform: "uppercase",
                  margin: 0,
                  fontSize: 11,
                  letterSpacing: 4,
                  position: "relative",
                  display: "inline-block",
                  marginLeft: "20px",
                },
                key !== "home" && {
                  "&:before": {
                    position: "absolute",
                    top: 2,
                    left: -22,
                    width: 4,
                    height: 4,
                    fontSize: 20,
                    content: "'•'",
                    color: "darkPurple.light",
                  },
                },
              ]}
            >
              <Link to={to ?? "/"} style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    textDecoration: "none !important",

                    color: "white.main",
                    fontSize: 24,
                    fontWeight: 700,
                    "&::before": {
                      position: "absolute",
                      bottom: -2,
                      left: "50%",
                      width: 0,
                      height: 1,
                      marginLeft: 0,
                      content: "''",
                      transitionDuration: ".2s",
                    },
                    "&:hover::before": {
                      width: "calc(100% - 4px)",
                      marginLeft: "-50%",
                    },

                    "&:hover": {
                      color: "yellow.main",
                    },
                  }}
                >
                  {label}
                </Box>
              </Link>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
