import { Box, Grid, Typography } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import List from "../components/list";

import Section from "../components/Section";
import SubTitleHeader from "../components/SubTitle";
import FrequentlyAskedQuestionsView from "./components/FrequentlyAskedQuestionsView";
import {
  atTheFestQuestions,
  generalQuestions,
  otherQuestions,
} from "./components/questions";
import GettingHereScreen from "./GettingHereScreen";

export const textDecoration = {
  textDecorationColor: "#f1dd84",
  textDecoration: "none",
};

export const linkStyles = { color: "yellow.main" };
const FestivalInformationScreen = () => {
  return (
    <Box>
      <Section title="Festival Info">
        <Grid item xs={12}>
          <SubTitleHeader subTitle="General Info" />
        </Grid>
        <Typography
          sx={{
            fontSize: 16,
            marginBottom: "8px",
          }}
          color="primary"
        >
          Amplitude Festival is a small attendee-run bespoke event, for friends
          of Ministry of Send, that will be held in George, WA.
          The event will consist of activities and DJ performances during the
          day, followed by DJ performances at night. It is an entirely
          attendee-run event. All attendees are encouraged to bring their own
          activity, music, and/or art, in addition to, and most importantly,
          their presence.
        </Typography>

        <SubTitleHeader subTitle="Jump to an offering…" />
        <Grid sx={{ marginBottom: "10px" }}>
          <Link to={"#GettingHere"} style={textDecoration}>
            <Typography sx={linkStyles}>Getting Here</Typography>
          </Link>
          <Link to={"#WhatToExpect"} style={textDecoration}>
            <Typography sx={linkStyles}>What To Expect</Typography>
          </Link>
          <Link to={"#FAQ"} style={textDecoration}>
            <Typography sx={linkStyles}>Frequently Asked Questions</Typography>
          </Link>
        </Grid>
      </Section>
      {/* Getting Here */}
      <Section>
        <Grid container>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <SubTitleHeader anchor="GettingHere" subTitle="Getting Here" />
              </Grid>
              <GettingHereScreen />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <SubTitleHeader subTitle="Gate Info" />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingRight: { xs: "10px", sm: "40px", md: "100px" },
                  }}
                  color="primary"
                  paragraph
                >
                  Plan your arrival! Gates open at 12pm on Thursday. Your camp
                  site must be clean and empty by 2pm on Sunday. From 10pm to
                  12am, you can pay a $30 late arrival fee in cash.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                    paddingRight: { xs: "10px", sm: "40px", md: "100px" },
                  }}
                  color="primary"
                  paragraph
                >
                  Gate Hours:
                </Typography>
                <List
                  items={[
                    "Thursday: 12pm-10pm",
                    "Friday: 10am-10pm",
                    "Saturday: 12am-10pm",
                    "Sunday: MUST LEAVE BY 2PM",
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      {/* What to expect */}
      <Section>
        <Grid container>
          <Grid item xs={12}>
            <SubTitleHeader anchor="WhatToExpect" subTitle="What To Expect" />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "20px",
              paddingBottom: "20px",
              borderBottom: 2,
              borderColor: "teal.dark",
            }}
          >
            <List
              items={[
                "Parking is charged per vehicle (one per campsite) and valid for the entire weekend. Vehicles are allowed in-and-out privileges during gate hours. Please carpool!",
                "Weather at the Gorge during May can be unpredictable. Daytime temperatures range from 60 to 100 degrees and nighttime temperatures can drop drastically. Prepare to have fun, rain or shine!",
                "Trailer toilets and showers are located throughout the campgrounds. There is no electricity in the campgrounds, so please plan accordingly.",
                "Potable water is available in the bathrooms and sinks for free throughout the venue. Ice is available for purchase inside the venue from 8am-6pm ($10 per 20lb bag, cash only).",
              ]}
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <SubTitleHeader subTitle="Bring It With You" />
              </Grid>
              <Grid item xs={12}>
                <List
                  items={[
                    "Sunglasses",
                    "Sunscreen",
                    "Water bottle",
                    "Camping gear (tent, tarp, sleeping bags, etc.)",
                    "Lantern/flashlight/headlamp with extra batteries",
                    "Rain gear",
                    "Hat or bandana",
                    "Warm Hoodie",
                    "Towel",
                    "Soap & Shampoo",
                    "Earplugs",
                    "Theme day costumes",
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <SubTitleHeader subTitle="Leave it at Home" />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <List
                    items={[
                      "No pets (dogs/cats/etc)",
                      "No drones (unless you have explicit permission from fest organizers)",
                      "No fireworks",
                      "No weapons",
                      "No gas, wood, or charcoal grills (small propane camping stoves are okay on the gravel)",
                      "No personal motorized vehicles (incl. scooters, ATVs, powered skateboards, etc)",
                      "No open fires or candles",
                      "No pools or kiddie pools",
                      "No kegs, mini-kegs, or binge-drinking devices",
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      {/* FAQ */}
      <Section>
        <Grid container>
          <Grid item xs={12}>
            <SubTitleHeader anchor="FAQ" subTitle="FAQ" />
          </Grid>

          <FrequentlyAskedQuestionsView
            questions={generalQuestions}
            subTitle="General"
          />

          <FrequentlyAskedQuestionsView
            questions={atTheFestQuestions}
            subTitle="At the Fest"
          />

          <FrequentlyAskedQuestionsView
            questions={otherQuestions}
            subTitle="Other Questions"
          />
        </Grid>
      </Section>
    </Box>
  );
};

export default FestivalInformationScreen;
