import { Box, Typography } from "@mui/material";

const List = ({ items }: { items: string[] }) => {
  return (
    <Box component="ul" sx={{ paddingLeft: { xs: "24px", sm: "40px" } }}>
      {items.map((item) => (
        <Box
          component="li"
          color="primary"
          sx={{
            marginBottom: "4px",
            " &::marker": { color: "white.main" },
          }}
        >
          <Typography color="primary">{item}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default List;
