import { Box, Grid } from "@mui/material";

import Tile from "./components/Tile";
import image1 from "../assets/image5.jpeg";
import image2 from "../assets/image3.jpeg";
import image3 from "../assets/image1.jpeg";
import iconImage from "../assets/ticket-icon.png";
import lineupIcon from "../assets/headphones.png";
import snowIcon from "../assets/snow.png";

const gridStyles = { justifyContent: "center", flexWrap: { lg: "nowrap" } };
const HomeScreen = () => {
  return (
    <Box>
      <Grid id="grid" container spacing={2} sx={gridStyles}>
        <Grid item>
          <Tile
            title="Get Tickets"
            iconImage={iconImage}
            backgroundImage={image1}
            link="/tickets"
          />
        </Grid>

        <Grid item>
          <Tile
            title="Lineup? (not yet)"
            iconImage={lineupIcon}
            backgroundImage={image2}
            link="/lineup"
          />
        </Grid>

        <Grid item>
          <Tile
            title="FAQ"
            iconImage={snowIcon}
            backgroundImage={image3}
            link="/festival-information"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeScreen;
