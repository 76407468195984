import { useState, useEffect } from "react";
import { AppBar, Hidden, Toolbar } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { DesktopNav, MobileNav } from "./index";
import { Theme } from "../theme/types/createPalette";
import { MAX_SCREEN_WIDTH } from "../theme/constants";
import clsx from "clsx";
import DaysUntilBanner from "./DaysUntilBanner";

export default function Navigation() {
  const { classes } = useStyles();
  const [showDaysUntilBanner, setShowDaysUntilBanner] = useState(false);

  const listenScrollEvent = (e: any) => {
    const showBannerAt = 60;
    setShowDaysUntilBanner(window.scrollY > showBannerAt);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <>
      <AppBar
        className={clsx(classes.subHeader, {
          [classes.subHeaderActive]: showDaysUntilBanner,
        })}
      >
        <DaysUntilBanner />
      </AppBar>
      <AppBar
        className={clsx(classes.root, {
          [classes.subHeaderActiveRoot]: showDaysUntilBanner,
        })}
      >
        <div className={classes.container}>
          <Hidden only={["xs", "sm"]}>
            <DesktopNav />
          </Hidden>

          <Hidden only={["md", "lg", "xl"]}>
            <Toolbar classes={{ root: classes.navContainerRootMobile }}>
              <MobileNav />
            </Toolbar>
          </Hidden>
        </div>
      </AppBar>
    </>
  );
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    subHeader: {
      fontWeight: 700,
      height: 60,
      left: 0,
      lineHeight: 60,
      position: "fixed",
      textAlign: "center",
      textTransform: "uppercase",
      top: "-60px",
      transitionDuration: ".9s",
      transitionProperty: "top,transform",
      width: "100%",
      zIndex: 15,
    },
    subHeaderActive: {
      top: "0px !important",
      transitionDuration: ".6s",
      transitionProperty: "top,transform",
    },
    container: {
      margin: "auto",
      backgroundColor: theme.palette.darkPurple.main,
      maxWidth: 1750,
      [theme.breakpoints.up(MAX_SCREEN_WIDTH)]: {
        borderLeft: `1px solid #bdbdbd`,
        borderRight: `1px solid #bdbdbd`,
      },
      [theme.breakpoints.down("sm")]: {
        margin: "unset",
      },
    },
    root: {
      top: "0px",
      transitionDuration: ".9s",
      transitionProperty: "top,transform",
      backgroundColor: theme.palette.darkPurple.main,
      boxShadow: "none",
    },
    subHeaderActiveRoot: {
      top: "60px !important",
      transitionDuration: ".9s",
      transitionProperty: "top,transform",
    },
    navContainerRootMobile: {
      background: theme.palette.darkPurple.main,
      height: "60px",
    },
  };
});
