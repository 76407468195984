import { Grid, Typography } from "@mui/material";

export type SectionProps = {
  /** Title of  section */
  title?: string;
  /** The children */
  children: React.ReactNode;
};

const Section = ({ title, children }: SectionProps) => {
  return (
    <Grid
      id="section"
      sx={{
        backgroundColor: "blue.light",
        height: "fit-content",
        marginBottom: "60px",
      }}
      container
      alignContent="baseline"
    >
      <Grid
        sx={[
          {
            borderBottom: 3,
            backgroundColor: "darkPurple.main",
            borderColor: "yellow.main",
            paddingLeft: { xs: "14px", sm: "38px" },
          },
        ]}
        item
        xs={12}
      >
        {title ? (
          <Typography
            sx={{
              padding: "12px",
              fontSize: { xs: 20, sm: 34 },
              color: "yellow.main",
              textTransform: "uppercase",

              fontWeight: 900,
              paddingLeft: { xs: "14px", sm: "10px" },
            }}
          >
            {title}
          </Typography>
        ) : null}
      </Grid>

      <Grid item xs={12} sx={[{ padding: { xs: "10px", sm: "24px 48px" } }]}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Section;
