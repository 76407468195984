import React from "react";

import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import { navItems } from "./constants";
import Logo from "./Logo";

import {
  Button,
  SwipeableDrawer,
  List,
  Typography,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";

export default function MobileNav() {
  const [drawerOpenState, setDrawerOpenState] = React.useState(false);

  return (
    <>
      <Logo />
      <Box
        sx={{
          flex: 1,
          justifyContent: "flex-end",
          textAlign: "right",
        }}
      >
        <Button
          aria-label="Navigation Menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(event) => setDrawerOpenState(true)}
        >
          <MenuIcon
            sx={{
              color: "primary.main",
              fontSize: "2.5rem !important",
            }}
          />
        </Button>
      </Box>
      <SwipeableDrawer
        data-testid="MobileNavDrawer"
        anchor="right"
        open={drawerOpenState}
        onClose={() => setDrawerOpenState(false)}
        onOpen={() => setDrawerOpenState(true)}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "darkPurple.main",
            borderLeft: 2,
            borderColor: "yellow.main",
          },
        }}
      >
        <Box
          sx={{ width: 300, maxWidth: "90vw" }}
          role="presentation"
          onClick={() => setDrawerOpenState(false)}
          onKeyDown={() => setDrawerOpenState(true)}
        >
          <List id="MobileNavItems">
            {Object.entries(navItems).map(([key, { to, label }]) => {
              return (
                <ListItem key={key} button>
                  <ListItemText>
                    <Link style={{ textDecoration: "none" }} to={to ?? "/"}>
                      <Typography
                        sx={{
                          marginLeft: "10px",
                          fontSize: 20,
                          fontWeight: "bold",
                          letterSpacing: 1,
                          color: "primary.main",

                          textTransform: "uppercase",
                        }}
                      >
                        {label}
                      </Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
}
