import { Box, Typography } from "@mui/material";

const SubTitleHeader = ({
  subTitle,
  anchor,
}: {
  subTitle: string;
  anchor?: string;
}) => {
  return (
    <>
      {anchor ? (
        <Box
          id={anchor}
          sx={{
            display: "block",
            position: "relative",
            top: { xs: "-140px", sm: "-160px" },
            visibility: "hidden",
          }}
        />
      ) : null}
      <Typography
        sx={{
          paddingBottom: 0,
          fontSize: { xs: 23, sm: 34, md: 30 },
          fontWeight: 800,
        }}
        color="primary"
        paragraph
      >
        {subTitle}
      </Typography>
    </>
  );
};

export default SubTitleHeader;
