import { Grid, Typography } from "@mui/material";
import Section from "../components/Section";

const LineupInformationScreen = () => {
  return (
    <Section title="Lineup Schedule">
      <Grid sx={{ padding: "20px" }}>
        <Typography
          sx={{
            padding: "20px",
            textAlign: "center",
            fontSize: 44,
            fontWeight: 800,
          }}
          color="primary"
          paragraph
        >
          CHECK BACK SOON FOR THE SCHEDULE!
        </Typography>
      </Grid>
    </Section>
  );
};

export default LineupInformationScreen;
