import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export type TileProps = {
  /** Title of Tile */
  title: string;
  /** The Icon to show */
  iconImage: any;
  /** The background image */
  backgroundImage: any;
  /** Link to go to  */
  link: any;
};

const Tile = ({ title, iconImage, backgroundImage, link }: TileProps) => {
  const [hoverOn, setHoverOn] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    history.push(`${link}`);
  };

  return (
    <Grid
      id="Tile"
      sx={{
        backgroundColor: "background.default",
        height: "fit-content",
        maxWidth: "100%",
        cursor: "pointer",
        justifyContent: "center",
      }}
      container
      alignContent="baseline"
      onMouseEnter={() => setHoverOn(true)}
      onMouseLeave={() => setHoverOn(false)}
      onClick={handleClick}
    >
      <Box
        sx={{
          margin: 0,
          position: "relative",
          maxHeight: "250px",
          width: "auto",
          overflow: "hidden",
          background: "#f1dd84",
          "&::before": {
            display: "block",
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            width: "95%",
            height: "93%",
            margin: "auto",
            border: "1px solid #f1dd84",
            zIndex: 1,
            transition: ".3s",
          },

          "&:hover": {
            img: {
              "&#backgroundImage": {
                transform: "scale(1.1)",
                transition: ".3s",
              },
            },

            "&::before": {
              width: "85%",
              height: "83%",
              borderColor: "white.main",
            },
          },
          "&::after": {
            display: "block",
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            background: "rgba(0,0,0,.7)",
            width: "100%",
            height: "100%",
            zIndex: 0,
          },
        }}
      >
        <Box
          component="img"
          id="backgroundImage"
          src={backgroundImage}
          sx={[
            {
              transition: ".3s",
              maxWidth: "100%",
              width: 390,
              height: 250,

              // width: { xs: 390, sm: 300, md: 350, lg: 350 },
              // height: { xs: 250, sm: 250, md: 240, lg: 250 },
              maxHeight: "auto",
            },
            hoverOn && { opacity: 0.5 },
          ]}
        ></Box>

        <Grid
          container
          sx={{
            zIndex: 2,
            flexDirection: "column",
            position: "absolute",
            alignItems: "center",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Box
            component="img"
            src={iconImage}
            sx={{
              width: 138,
              marginBottom: "14px",
            }}
          />
          <Typography
            sx={[
              {
                color: "white.main",
                fontSize: 24,
                fontWeight: 600,
                textTransform: "uppercase",
              },
              hoverOn && {
                color: "yellow.main",
              },
            ]}
          >
            {title}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Tile;
