import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import AmpFestLogo from "./assets/amp_fest.png";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [opacity, setOpacity] = useState(1);
  const [isHomePage, setIsHomePage] = useState(true);

  const location = useLocation();

  useEffect(() => {
    setIsHomePage(location.pathname === "/");
  }, [location]);

  const listenScrollEvent = (e: any) => {
    const startDisappearScrollY = 50;
    const disappearAtScrollY = 500;

    if (window.scrollY >= disappearAtScrollY) {
      setOpacity(0);
      return;
    }

    if (window.scrollY >= startDisappearScrollY) {
      const offset = window.scrollY - startDisappearScrollY;
      const opacityPercent = offset / 200;
      setOpacity(1 - opacityPercent + 0.1);
    } else {
      setOpacity(1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <Box
      sx={[
        {
          width: "100vw",
          marginBottom: "58px",
        },
        isHomePage && {
          height: "calc(100vh - 180px)", // TODO only if home page
        },
      ]}
    >
      <Grid
        container
        sx={{ textAlign: "center", opacity }}
        alignContent="baseline"
      >
        <Grid sx={{ textAlign: "center", opacity }} item xs={12}>
          <Box
            component="img"
            sx={[
              {
                // width: { xs: "100vw", sm: "100vw", md: 500, lg: 700 },
                // maxWidth: { xs: "100vw", sm: "100vw" },
                width: "auto",
                maxHeight: 500,
              },
              {
                maxHeight: { xs: 340 },
              },
            ]}
            alt="Amplitude Festival Logo"
            src={AmpFestLogo}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={[
              {
                textTransform: "uppercase",
                letterSpacing: "2px",
                fontSize: 44,
                color: "yellow.main",
                fontWeight: 700,
              },
              { fontSize: { xs: 30 } },
            ]}
          >
            May 12-15, 2022
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
