import { Box, Link } from "@mui/material";
import AmpFestLogo from "../assets/amp_fest.png";

export default function Logo() {
  return (
    <Link href="/" sx={{ color: "primary.main", textDecoration: "none" }}>
      <Box
        component="img"
        sx={[
          {
            width: "auto",
            height: 50,
          },
        ]}
        alt="Amplitude Festival Logo"
        src={AmpFestLogo}
      />
    </Link>
  );
}
