import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: `Mulish, Sans Serif`,
    fontWeightRegular: 450,
    fontWeightMedium: 600,
    fontWeightBold: 750,
    body1: {
      fontWeight: 450,
    },
  },

  palette: {
    background: {
      default: "#092253",
    },
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#5ba3b4",
    },
    darkPurple: {
      light: "#bd7aca",
      main: "#010326",
      dark: "#092253",
    },
    blue: { light: "#163760", main: "#445D90", dark: "#654481" },
    white: {
      main: "#fff",
    },
    yellow: {
      main: "#d2fb51",
    },
    orange: {
      main: "#f1dd84",
    },
    green: {
      main: "#5ca68f",
      dark: "#3e94f7",
    },
    teal: {
      light: "#e7358a",
      main: "#54c5ec",
      dark: "#445D90",
    },
    gray: {
      main: "#414141",
    },
    darkGray: {
      main: "#272B2F",
    },
  },
});

export default theme;
