import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        height: "200px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        marginBottom: "60px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          textTransform: "uppercase",
          color: "teal.main",
        }}
      >
        © Amplitude Festival 2022
      </Typography>
    </Box>
  );
};

export default Footer;
