import { Grid, Typography } from "@mui/material";
import Section from "../components/Section";
import SubTitleHeader from "../components/SubTitle";
import List from "../components/list";

const TermsAndConditionsScreen = () => {
  return (
    <Section title="Amplitude Festival Participant Agreement">
      <Grid sx={{ padding: "20px" }}>
        <Typography
          sx={{
            fontSize: 16,
            marginBottom: "8px",
          }}
          color="primary"
        >
          I, hereafter referred to as “Participant” hereby executes
          this Agreement with Litheon, LLC (“Litheon”) and agrees to the terms herein as conditions of
          entry.  Violation of these terms is grounds for immediate removal from the premises without
          refund.
        </Typography>
      </Grid>

      <Grid sx={{ padding: "20px" }}>
        <Grid item xs={12}>
          <SubTitleHeader subTitle="Release and Waiver" />
        </Grid>
        <Typography
          sx={{
            fontSize: 16,
            marginBottom: "8px",
          }}
          color="primary"
        >
          In consideration for being permitted to participate in the event “Amplitude Festival”, located
          in Washington State, occurring May 12th-15th, 2022, I, being of lawful age and on behalf of
          myself, my heirs, executors, administrators and assigns, do hereby waive, release, and discharge
          and agree not to sue Litheon, its officers, directors, members, employees, volunteers,
          representatives, agents, contractors and subcontractors, and other participants, as well as owners
          and lessors of equipment, art installations and premises used to conduct the event or related
          activities (collectively the “Releasees”), from any and all claims for damages, injuries, losses,
          liabilities and expenses which I may have or which may subsequently accrue to me, relating to,
          resulting from or arising out of my participation.  I agree to indemnify, defend and hold the
          Releasees harmless and understand that this Waiver shall be construed broadly to the maximum extent
          permissible by law.
        </Typography>
      </Grid>

      <Grid sx={{ padding: "20px" }}>
        <Grid item xs={12}>
          <SubTitleHeader subTitle="Conditions of Participation" />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: 16,
              marginBottom: "8px",
            }}
            color="primary"
            paragraph >
            I hereby acknowledge and agree:
          </Typography>
            <List
              items={[
                "I am 21 years of age or older, and am agreeing to this agreement without coercion.",
                "My safety is my personal responsibility and I do not have any physical limitations, medical ailments, physical or mental disabilities that would prevent me from safely participating in this event.",
                "I will abide by all rules, regulations and policies set forth for by production team members in addition to the specific venue rules enumerated below.",
                "Weapons of any kind, illegal drugs/substances/paraphernalia, and underage drinking are all strictly prohibited by Washington Law.",
                "The worldwide COVID-19 pandemic is still ongoing, and I will take appropriate precautions based on my vaccination status and the recommendations of the CDC and Washington State Health Officials.  I voluntarily assume the risk of potential exposure and infection which may result from the actions, omissions, or negligence of other participants.",
                "Cancellation of this event due to natural disaster, inclement weather, or changes in National or State Health Policy may result in loss of venue rental monies and other fixed costs by Litheon, and refunds to ticket-holders therefore cannot be guaranteed. Litheon will make a good faith effort to reimburse ticket-holders to the extent possible.",
                "My ticket is non-transferable except by the procedures detailed by Litheon and subject to the Transferee’s written acceptance of this Agreement. I agree that failure to ensure any subsequent purchaser of my ticket has read and accepted these terms may result in voiding said ticket and all associated privileges.",
              ]} />
          </Grid>
      </Grid>

      <Grid sx={{ padding: "20px" }}>
        <Grid item xs={12}>
          <SubTitleHeader subTitle="Venue Rules and Regulations" />
        </Grid>
        <Grid item xs={12}>
            <List
              items={[
                "Speed limit on premises is 5mph",
                "No standing on picnic tables",
                "Strictly obey all “No Trespassing” signs",
                "No pets allowed",
                "All trash deposited in receptacles provided",
                "No wood fires",
                "Propane firepits permitted only on gravel areas",
                "No BBQs on grass or picnic tables",
                "Kegs or other binge drinking device prohibited",
                "No use of portable pools",
                "No discharge of grey/black/excess water on the property",
                "No amplified sound between the hours of 2am-8am",
                "Drones may not be operated without express written permission of Litheon subject to any additional rules and restrictions there described",
              ]} />
          </Grid>
      </Grid>
    </Section>
  );
};

export default TermsAndConditionsScreen;
