export const generalQuestions = [
  {
    question: "Where can I buy tickets?",
    answer: `When released, tickets will be available on Ticket Tailor at: https://buytickets.at/ministryofsend/656527`,
  },
  {
    question: "Are tickets refundable?",
    answer: `Tickets are non-refundable. In the event of a new COVID-19 related restriction that requires cancelling this event, ticket purchasers will be given a credit for future Ministry of Send events (not a refund). If the event is cancelled we will make a good faith effort to refund any monies we are able to recover from the venue, but make no guarantees that we will be able to. Natural disaster (fire, smoke, etc.) or inclement weather (significant rain, extreme temperatures, etc.) may require we cancel the event.`,
  },
  {
    question: "What if I already purchased my ticket and am unable to attend?",
    answer: `Please reach out to amplitude@ministryofsend.com, and we will try to help you reallocate your ticket to someone on the approved guest list. Do NOT sell your ticket to anyone not on the guest list - they will not be allowed into the festival.`,
  },
  {
    question: "How much is camping/parking?",
    answer: (
      <>
        There is a $295 charge per parking spot for the weekend. Each parking
        spot comes with a campsite. Parking spots
        <b> cannot </b>
        be purchased separately from campsites, and vice versa.
      </>
    ),
  },
  {
    question: "How many people fit on a campsite?",
    answer: (
      <>
        Each campsite pass is allowed a maximum of one car and 6 people. Each campsite is 18' x 40", enough room for (2) 2-4 person tents and one vehicle.
      </>
    ),
  },
  {
    question: "What does my ticket cost go towards?",
    answer: (
      <>
        Amplitude is a fully volunteer-run event. No one involved in organizing the festival makes
        any profit. We are always more than happy to share budgets and be transparent about
        how we're spending your money.
        <br /><br />
        Approximately ~55% of your ticket cost goes towards production costs (such as the stage,
        lighting, dance floor), ~20% of your ticket cost goes towards logistics (such as
        wristbands, transportations, lawyer fees), ~15% goes towards festival infrastructure
        (such as shade, art, safety lights), and ~10% goes towards programming.
        <br /><br />
        Our budget is currently an estimate based on various invoices and quotes that we have
        received so far, so our final numbers may not precisely match these approximated numbers.
      </>
    ),
  },
  {
    question: "Why are tickets more expensive than last year?",
    answer: (
      <>
        Amplitude is a fully volunteer-run event. No one involved in organizing the festival makes
        any profit. We are always more than happy to share budgets and be transparent about
        how we're spending your money.
        <br /><br />
        <b>Campsites:</b> Due to post-pandemic reopening and inflation, venue prices have gone up by
        $20 per campsite compared to last year. 100% of campsite proceeds go towards paying the
        cost of the venue.
        <br /><br />
        <b>Tickets:</b> Last year, we heavily relied on borrowing our big pieces of infrastructure from a very
        generous Burning Man camp - thank you Best Butt! With busy and conflicting schedules this
        year, we're having to rent or build much of this infrastructure ourselves. Furthermore,
        we're working to improve the production value compared to last year's event. This has
        resulted in a $15 per-ticket increase required to support the festival.
        <br /><br />
        <b>Fees:</b> With the scale of our event ineveitably comes the need to rely on large payment processors
        and ticketing systems to manage our attendees. This is why you also see an additional set
        of fees added to check-out on your ticket(s) this year.
      </>
    ),
  },
];

export const atTheFestQuestions = [
  {
    question: "Can I leave and then re-enter the festival?",
    answer: `Yes, once you have checked in and received your parking pass, you are free to pass in and out of the festival grounds DURING GATE HOURS.`,
  },
  {
    question: "What if I have to arrive after gate hours?",
    answer: `From 10pm to 12am, you can pay a $30 late arrival fee to enter the gate in cash only. After 12am, you will need to wait until the next day to enter the campgrounds. `,
  },
  {
    question: "Are there quiet hours?",
    answer: `Yes, 2am-8am.`,
  },
  {
    question: "Are there food vendors?",
    answer: `No. Please remember to bring your own food.`,
  },
  {
    question: "Is grilling allowed?",
    answer: `Yes, we request that all BBQs are used on the gravel areas only. Do not use them on top of the grass or picnic tables.`,
  },
  {
    question: "Is there cell phone service?",
    answer: `Yes, there is typically cell phone service at the campgrounds.`,
  },
  {
    question: "Can I bring my kids or underage relatives/friends?",
    answer: `No. Amplitude is a 21+ event.`,
  },
  {
    question: "Are there showers on-site?",
    answer: `Yes. They are available from 5-7pm on Thursday and 7-10am on Friday-Sunday. There is no additional charge to use the showers.`,
  },
  {
    question: "Are there RV hook-ups?",
    answer: `No.`,
  },
  {
    question:
      "Can I stay at a hotel nearby the venue and come visit during gate hours?",
    answer: `We strongly discourage doing this. You will still need to pay for a vehicle pass/campsite to enter the gates and park your car, and your vehicle will only be able to enter/exit during gate hours.`,
  },
  {
    question: "What are the COVID-19 guidelines?",
    answer: `By purchasing a ticket to Amplitude, you agree to all COVID-19 guidelines put in place by the organizers. These may include proof of vaccination and/or a negative COVID test within a certain time frame of entry. These protocols may change without notice. All sales are final and there are no refunds, regardless of your ability to attend due to this protocol.`,
  },
];

export const otherQuestions = [
  {
    question:
      "I have a question that I’m 300% SURE is not answered on this website. Who do I message?",
    answer: (
      <>
        Okay, but first check this website one more time! Then, if you’re
        absolutely sure it’s not answered, consider if the answer to your
        question would benefit everyone and therefore should be posted on the Facebook
        event for posterity. If not, then please reach out to amplitude@ministryofsend.com.
        <br />
        <br />
        Any questions messaged directly to leads will be ignored, and your name
        will be added to the Whiteclaw shame wall below.{" "}
      </>
    ),
  },
  {
    question:
      "Who owes Whiteclaws to the leads for asking questions that are already answered?",
    answer: `TBD`,
  },
];
