import { Grid, Link, Typography } from "@mui/material";

import Section from "../components/Section";
import SubTitleHeader from "../components/SubTitle";
import TicketOptionTile from "./TicketOptionTile";
import List from "../components/list";
// import { HashLink } from "react-router-hash-link";
// import {
//   textDecoration,
//   linkStyles,
// } from "../FestivalInformation/FestivalInformationScreen";

const TicketingInformationScreen = () => {
  return (
    <Grid container>
      <Section title="Tickets">
        <SubTitleHeader subTitle="General Ticketing Info" />

        <List
          items={[
            "Everyone attending Amplitude Festival 2022, must both arrive with a ticket and in a vehicle with a vehicle/camping pass.",
            "Amplitude has a curated guest list that you must be a member of to attend. Any tickets purchased by or for someone not on the guest list will be cancelled and refunded less fees.",
            "Gates open starting at 12pm on Thursday. All attendees and vehicles must leave the site by 2pm on Sunday.",
            "By purchasing a ticket to Amplitude Festival, you agree to all COVID-19 guidelines put in place by the Event Organizers, which may include proof of vaccination. These protocols may change without notice.",
            "All sales are final.",
          ]}
        />
      </Section>

      {/* General Camping Ticket */}
      <Section>
        <Grid container>
          <Grid item sx={{ flex: 1 }}>
            <SubTitleHeader
              anchor="GeneralCampingTicket"
              subTitle="General Vehicle Pass/Camping"
            />
            <Typography
              sx={{
                fontSize: 14,
                paddingRight: { xs: "10px", sm: "40px", md: "100px" },
              }}
              color="primary"
              paragraph
            >
              Your vehicle pass/camping pays for one camping spot that is 18’ x
              40’, with enough room for two 2-4 people tents and one vehicle or
              an RV. A max of 1 vehicle and 6 people are allowed per campsite.
              The entirety of your fee goes towards paying for the venue.
              <br />
              <br />
              Buy your ticket before March 31st to lock-in our early bird
              guarantee! If we do not sell enough early bird tickets before the
              deadline to meet the minimum venue requirements for rental, we
              will cancel the event and refund all campsites in full (including
              fees).
            </Typography>
          </Grid>
          <Grid item sx={{ width: { xs: "100%", md: "unset" } }}>
            <TicketOptionTile
              cost="295"
              link="https://buytickets.at/ministryofsend/656527"
            />
          </Grid>
        </Grid>
      </Section>

      {/* Early Bird */}
      <Section>
        <Grid container>
          <Grid item sx={{ flex: 1 }}>
            <SubTitleHeader
              anchor="EarlyBirdTicket"
              subTitle="Early Bird Ticket"
            />
            <Typography
              sx={{
                fontSize: 14,
                paddingRight: { xs: "10px", sm: "40px", md: "100px" },
              }}
              color="primary"
              paragraph
            >
              Your ticket price helps pay for our production rentals/costs (such
              as the stage rental, shared shade, storage, etc) and logistics
              expenses (such as wristbands, printing, etc).
              <br />
              <br />
              Buy your ticket before March 31st to lock-in early bird pricing
              and guarantee! If we do not sell enough early bird tickets before
              the deadline to meet the minimum venue requirements for rental, we
              will cancel the event and refund all tickets in full (including
              fees).
            </Typography>
          </Grid>
          <Grid item sx={{ width: { xs: "100%", md: "unset" } }}>
            <TicketOptionTile
              cost="55"
              link="https://buytickets.at/ministryofsend/656527"
            />
          </Grid>
        </Grid>
      </Section>

      {/* General Pass */}
      <Section>
        <Grid container>
          <Grid item sx={{ flex: 1 }}>
            <SubTitleHeader anchor="GeneralTicket" subTitle="General Ticket" />
            <Typography
              sx={{
                fontSize: 14,
                paddingRight: { xs: "10px", sm: "40px", md: "100px" },
              }}
              color="primary"
              paragraph
            >
              Your ticket price helps pay for our production rentals/costs (such
              as the stage rental, shared shade, storage, etc) and logistics
              expenses (such as wristbands, printing, etc).
            </Typography>
          </Grid>
          <Grid item sx={{ width: { xs: "100%", md: "unset" } }}>
            <TicketOptionTile
              cost="80"
              link="https://buytickets.at/ministryofsend/656527"
            />
          </Grid>
        </Grid>
      </Section>

      {/* Low Income Ticket */}
      <Section>
        <Grid container>
          <Grid item sx={{ flex: 1 }}>
            <SubTitleHeader
              anchor="LowIncomeTicket"
              subTitle="Low-Income Ticket"
            />
            <Typography
              sx={{
                fontSize: 14,
                paddingRight: { xs: "10px", sm: "40px", md: "100px" },
              }}
              color="primary"
              paragraph
            >
              We don't want cost to be a barrier to attending Amplitude Fest.
              You can request a low-income ticket here:{" "}
              <Link
                href="https://airtable.com/shr2YvhVzfyjjSCE8"
                target="_blank"
              >
                https://airtable.com/shr2YvhVzfyjjSCE8
              </Link>
              . Responses will only be viewable by Ali Tai and James, and will
              not be shared with anyone else. We will do our best to help out
              from our low-income ticket fund, and hope you’ll join us!
            </Typography>
          </Grid>
        </Grid>
      </Section>
    </Grid>
  );
};

export default TicketingInformationScreen;
