import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { Theme } from "../../theme/types/createPalette";
import SubTitleHeader from "../../components/SubTitle";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: "10px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.MuiAccordion-root": {
    backgroundColor: "unset",
    background: "unset !important",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowRightIcon sx={{ fontSize: { xs: "2rem", sm: "3rem" } }} />
    }
    {...props}
  />
))(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.teal.dark,
  border: "none",
  color: "white",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiAccordion-root": {
    backgroundColor: "unset",
    background: "unset",
  },

  "&:hover": {
    backgroundColor: theme.palette.darkPurple.dark,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    color: "white",
    background: theme.palette.blue.light,
    paddingBottom: "20px",
  })
);

const FrequentlyAskedQuestionsView = ({
  subTitle,
  questions,
}: {
  subTitle: string;
  questions: any[];
}) => {
  return (
    <Grid container sx={{ marginBottom: "34px" }}>
      <Grid item xs={12}>
        <SubTitleHeader subTitle={subTitle} />
      </Grid>
      <Grid item xs={12}>
        {questions?.map((q, index) => (
          <Accordion>
            <AccordionSummary
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography
                sx={{
                  fontSize: { xs: ".8em", sm: "1.25rem" },
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                {q.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{q.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default FrequentlyAskedQuestionsView;
