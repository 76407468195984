import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { checkPassword } from "../hooks/useKeys";

const FestivalInformationScreen = () => {
  const { search } = useLocation();
  const query = queryString.parse(search);

  const [allowedToEnter, setAllowedToEnter] = useState(false);
  const [textbox, setTextbox] = useState<string>((query?.code as string) ?? "");

  const handlePasswordVerification = async (e: any) => {
    const allowedToEnter = await checkPassword({ password: textbox?.trim() });
    setAllowedToEnter(allowedToEnter);
  };

  useEffect(() => {
    const check = async () => {
      if (!query.code) {
        return;
      }
      const allowedToEnter = await checkPassword({
        password: (query?.code as string) ?? "",
      });
      setAllowedToEnter(allowedToEnter);
    };
    check();
  }, [query?.code]);

  return (
    <Box sx={{ paddingRight: "30px" }}>
      {allowedToEnter ? (
        <Box>
          <Typography
            sx={{
              fontSize: 16,
              marginBottom: "8px",
            }}
            color="primary"
          >
            From Silica Road NW, take Terrace Lane directly to the Amplitude Festival entrance at the following dropped pin: https://goo.gl/maps/ZNvcMmzKFWu3Hna5A
            <br />
            <br />
            Note: Do <b>not</b> drive into General Camping or the Gorge Amphitheatre parking lot.
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              fontSize: 16,
              marginBottom: "14px",
            }}
            color="primary"
          >
            Location and directions to Amplitude Festival can be found in your
            email confirmation after ticket purchase, or by entering the code
            shared with the guest list here:
          </Typography>
          <Grid container alignContent="center" alignItems="center">
            <Grid item sx={{ width: { xs: "100%", sm: 200 } }}>
              <TextField
                onChange={(e) => setTextbox(e.target.value)}
                color="primary"
                id="outlined-basic"
                label="Password"
                variant="outlined"
                sx={{
                  backgroundColor: "blue.light",

                  "& .MuiInputLabel-root": { color: "white.main" },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "blue.light",
                    color: "white.main",
                    "& :-webkit-autofill": {
                      "-webkit-box-shadow": "0 0 0 100px #163760 inset",
                      "-webkit-text-fill-color": "#fff",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "teal.main",
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "teal.main",
                  },
                  marginRight: "20px",
                  width: { xs: "100%", sm: "initial" },
                }}
              />
            </Grid>
            <Grid item sx={{ width: { xs: "100%", sm: 100, height: "100%" } }}>
              <Button
                sx={{
                  height: "56px",
                  width: { xs: "100%", sm: "100px" },
                  marginTop: { xs: "8px", sm: "0" },
                }}
                variant="outlined"
                onClick={handlePasswordVerification}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default FestivalInformationScreen;
