import firebase from "../firebase";

export const checkPassword = async ({ password }: { password: string }) => {
  var db = firebase.firestore();

  const querySnapshot = await db
    .collection("keys")
    .where("code", "==", password)
    .get();

  return !querySnapshot.empty;
};
