import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

function ScrollToTop({ history }: any) {
  const location = useLocation();

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (!location.hash || location.hash === "") {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
