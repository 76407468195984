import { Box } from "@mui/material";
import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";

import HomeScreen from "./Home/HomeScreen";
import LineupInformationScreen from "./LineupInformation/LineupInformationScreen";
import TicketingInformationScreen from "./TicketingInformation/TicketingInformationScreen";
import FestivalInformationScreen from "./FestivalInformation/FestivalInformationScreen";
import TermsAndConditionsScreen from "./TermsAndConditions/TermsAndConditionsScreen";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

export default function Router() {
  const location = useLocation();

  return (
    <Box sx={{}}>
      <TransitionGroup>
        <CSSTransition timeout={550} classNames="fade" key={location.key}>
          <Box
            sx={{
              paddingTop: {
                xl: "140px",
                lg: "120px",
                md: "100px",
                sm: "90px",
                xs: "70px",
              },
              marginTop: "60px",
              minHeight: "calc( 100vh - 60px )",
              paddingBottom: "20px",
              display: "flex",
              width: "100vw",
            }}
          >
            <Grid container alignContent="baseline">
              <Grid item xs={12}>
                <Header />
              </Grid>
              <Grid
                sx={{
                  paddingLeft: { xs: "18px", sm: "80px", md: "120px" },
                  paddingRight: { xs: "18px", sm: "80px", md: "120px" },
                }}
                item
                xs={12}
              >
                <ScrollToTop />
                <Switch location={location}>
                  <Route path="/" exact={true}>
                    <HomeScreen />
                  </Route>

                  <Route path="/festival-information" exact={true}>
                    <FestivalInformationScreen />
                  </Route>

                  <Route path="/tickets" exact={true}>
                    <TicketingInformationScreen />
                  </Route>

                  <Route path="/lineup" exact={true}>
                    <LineupInformationScreen />
                  </Route>

                  <Route path="/toc" exact={true}>
                    <TermsAndConditionsScreen />
                  </Route>

                  {/* <Route path="/volunteer" exact={true}>
                    <VolunteerInformationScreen />
                  </Route> */}

                  <Redirect to={{ pathname: "/" }} />
                </Switch>
              </Grid>
              <Grid item xs={12}>
                <Footer />
              </Grid>
            </Grid>
          </Box>
        </CSSTransition>
      </TransitionGroup>
    </Box>
  );
}
