import { TNavItem } from "./types";

export const navItems: { [fieldName: string]: TNavItem } = {
  home: {
    to: "/",
    label: "Home",
  },
  tickets: {
    to: "/tickets",
    label: "Tickets",
  },
  lineup: {
    to: "/lineup",
    label: "Lineup",
  },
  festivalInfo: {
    to: "/festival-information",
    label: "Festival Info",
  },
  terms: {
    to: "/toc",
    label: "terms",
  },
  // volunteer: {
  //   to: "/volunteer",
  //   label: "Volunteer",
  // },
  // som
};
