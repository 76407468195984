import { Grid, Typography } from "@mui/material";
import { useState } from "react";

export type TicketOptionTileProps = {
  /** cost of ticket*/
  cost: string;
  /** Link to go to  */
  link: any;
};

const alignCenterStyles = {
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  display: "flex",
};

const TicketOptionTile = ({ cost, link }: TicketOptionTileProps) => {
  const [hoverOn, setHoverOn] = useState(false);

  const handleClick = () => {
    window.open(link, "_blank");
  };

  return (
    <Grid
      id="TicketOptionTile"
      sx={[
        {
          backgroundColor: "background.default",
          width: 200,
          height: 162,
          cursor: "pointer",
          marginBottom: "40px",
          marginTop: "10px",
          position: "relative",

          "&::after": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            opacity: 0,
            transition: "all .3s",
            border: "1px solid hsla(0,0%,100%,.5)",
            transform: "scale(1.2)",
          },
        },
        {
          width: { xs: "100%", sm: "100%", md: 200 },
        },
        hoverOn && {
          "&::after": {
            opacity: 1,
            transform: "scale(1)",
          },
        },
      ]}
      container
      alignContent="baseline"
      onMouseEnter={() => setHoverOn(true)}
      onMouseLeave={() => setHoverOn(false)}
      onClick={handleClick}
    >
      <Grid
        item
        xs={12}
        sx={[
          alignCenterStyles,
          {
            height: 120,
            backgroundColor: "green.dark",
            transition: ".6s",
          },
          hoverOn && {
            backgroundColor: "white.main",
          },
        ]}
      >
        <Typography
          sx={[
            {
              fontSize: 28,
              fontWeight: 700,

              transition: ".6s",
            },
            hoverOn && {
              color: "green.dark",
            },
          ]}
        >
          ${cost}
        </Typography>
        <Typography
          sx={[
            {
              fontSize: 16,
              textTransform: "uppercase",
              fontWeight: 500,

              transition: ".6s",
            },
            hoverOn && {
              color: "green.dark",
            },
          ]}
        >
          +fees
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={[
          alignCenterStyles,
          { height: 44, backgroundColor: "white.main", transition: ".6s" },
          hoverOn && {
            backgroundColor: "green.dark",
          },
        ]}
      >
        <Typography
          sx={[
            {
              fontSize: 18,
              textTransform: "uppercase",

              transition: ".6s",
            },
            hoverOn && {
              color: "white.main",
            },
          ]}
        >
          Buy
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TicketOptionTile;
